// Image Imports
import alexHormozi from "../../assets/img/idx/tm_section_1.webp";
import sweat from "../../assets/img/idx/tm_section_2.webp";
import saski from "../../assets/img/idx/tm_section_3.webp";
import defamation from "../../assets/img/idx/tm_author_4.webp";
import revengePorn from "../../assets/img/idx/tm_author_5.webp";

// Functional Component
export default function CaseStudiesSection() {
  return (
    <div className="section-content">
      <h2>Case Studies</h2>
      <br/>
      <div className="section-group">
        <div className="links-group">
          <h4>Explore</h4>
          <a href="/testimonials">Testimonials</a>
          <a href="/case-studies">Case Studies</a>
        </div>
        <br/><br/>
        <div className="links-group">
          <h4>Success Stories</h4>
          <a href="/alex-hormozi">
            <img className="rb" src={alexHormozi.src} alt="Alex Hormozi" />
            Alex Hormozi
          </a>
          <a className="rb" href="/sweat-case-study">
            <img className="rb" src={sweat.src} alt="Sweat.com" />
            Sweat.com
          </a>
          <a className="rb" href="/saski-case-study">
            <img className="rb" src={saski.src} alt="Saski" />
            Saski Collection
          </a>
          <a href="/defamation-case-study">
            <img className="rb" src={defamation.src} alt="Defamation" />
            Defamation
          </a>
          <a href="/revenge-porn-case-study">
            <img className="rb" src={revengePorn.src} alt="Revenge Porn" />
            Revenge Porn
          </a>
        </div>
      </div>
    </div>
  );
} 